/**
 * Utility functions for working with TypeScript enums.
 */

/**
 * The closest facsimile we can get to a general enum type, since TypeScript doesn't currently
 * provide an Enum superclass. Inspired by https://stackoverflow.com/a/50159864/1237044.
 */
export type Type<E> = Record<keyof E, number | string> & { [k: number]: string };
export type OrdinalValue<E extends Type<E>> = E[keyof E] & number;

/**
 * Returns the array of string keys for the given enum. A default TypeScript enum maps the ordinal
 * values to the corresponding string values and the string values to the corresponding ordinal
 * values. This makes it easy to map back and forth, but it means iterating over all values isn't
 * straightforward.
 */
export function stringKeys<E extends Type<E>>(anEnum: E): (keyof E & string)[] {
    return Object.keys(anEnum).filter(<(k: any) => k is keyof E & string>isNaN);
}

/**
 * Provides a set for TypeScript enum values. The implementation uses a compact and CPU-efficient
 * bigint-based representation. Declared as Enum.Set<typeof SomeEnum>.
 */
class EnumSet<E extends Type<E>> {
    /** Returns an Enum.Set<typeof E> that contains every value of E. */
    static allOf<E extends Type<E>>(theEnum: E): EnumSet<E> {
        const size = stringKeys(theEnum).length;
        return new EnumSet(theEnum, EnumSet.allSet(size), size);
    }

    /** Returns an empty Enum.Set<typeof E> of E values. */
    static noneOf<E extends Type<E>>(theEnum: E): EnumSet<E> {
        return new EnumSet(theEnum, 0n);
    }

    /**
     * Returns an Enum.Set<typeof E> that contains the values specified by the `items` bit vector.
     * `BigInt(items)` must result in a BigInt where the i-th bit is set iff the E value with
     * ordinal i is contained in the set.
     */
    static fromBitVector<E extends Type<E>>(
        theEnum: E,
        items: string | number | bigint,
    ): EnumSet<E> {
        return new EnumSet(theEnum, BigInt(items));
    }

    static copyOf<E extends Type<E>>(set: EnumSet<E>): EnumSet<E> {
        return new EnumSet(set.theEnum, set.items, set.size);
    }

    /** Returns a bigint where every bit from [0...n) is set. */
    private static allSet(n: number): bigint {
        return (1n << BigInt(n)) - 1n;
    }

    private constructor(
        private theEnum: E,
        private items: bigint,
        private size = stringKeys(theEnum).length,
    ) {}

    /** Adds item to this set. */
    add(item: OrdinalValue<E>): void {
        this.items |= 1n << BigInt(item);
    }

    /** Removes item from this set. */
    remove(item: OrdinalValue<E>): void {
        const mask = EnumSet.allSet(this.size) - (1n << BigInt(item));
        this.items &= mask;
    }

    /** Returns true iff this set contains item. */
    has(item: OrdinalValue<E>): boolean {
        return (this.items & (1n << BigInt(item))) !== 0n;
    }

    *[Symbol.iterator]() {
        let mask = this.items;
        for (let i = 0; mask !== 0n; i++) {
            if ((mask & 1n) !== 0n) {
                yield i;
            }
            mask >>= 1n;
        }
    }

    /**
     * JSONify as an array of the items' enum strings (names). This representation should be easy
     * for the backend to parse into an Iterable<E>.
     */
    toJSON() {
        return Array.from(this).map((ord) => this.theEnum[ord]);
    }
}
export type Set<E extends Type<E>> = EnumSet<E>;
export const Set = EnumSet;

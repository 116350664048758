import * as Base from "Everlaw/Base";
import { MinimalOrganization } from "Everlaw/MinimalOrganization";
import * as Rest from "Everlaw/Rest";

export class AccessRestrictions {
    // Eventually it may make sense to standardize some kind of notification message here, but for
    // now we store the org name and use it in one-off notification messages.
    orgName: string;

    constructor(
        public cjis: boolean,
        public sensitive: boolean,
        orgId: number,
    ) {
        const org = Base.get(MinimalOrganization, orgId);
        this.orgName = org ? org.name : `[Org ${orgId}]`;
    }

    static fromEntity(
        entityType: AccessRestrictionsEntityType,
        id: number,
    ): Promise<AccessRestrictions> {
        return Rest.get("/accessRestrictions.rest", { entityType, id }).then(
            (data) => new AccessRestrictions(data.cjis, data.sensitive, data.orgId),
        );
    }
}

export enum AccessRestrictionsEntityType {
    PROJECT = "PROJECT",
    DATABASE = "DATABASE",
    ORGANIZATION = "ORGANIZATION",
}

import Base = require("Everlaw/Base");
import { OrganizationId } from "Everlaw/MinimalOrganization";

export class OrgLabel extends Base.Object {
    get className() {
        return "OrgLabel";
    }
    override id: OrgLabelId;
    identifier: string;
    abbreviation: string;
    orgId: number;

    constructor(params: any) {
        super(params);
        this._mixin(params);
    }

    override _mixin(params: any) {
        Object.assign(this, params); // take them all
    }

    override display() {
        return this.identifier;
    }
}

type OrgLabelId = number & Base.Id<"OrgLabel">;

export const isValidLabelName = (name: string, orgId: OrganizationId, labelId?: number): boolean =>
    !Base.get(OrgLabel).some(
        (label) =>
            label.orgId === orgId
            && label.id !== labelId
            && name.toUpperCase() === label.identifier.toUpperCase(),
    );

if (JSP_PARAMS.OrgLabel) {
    Base.set(OrgLabel, JSP_PARAMS.OrgLabel);
}
